import React from 'react'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import {Container} from 'reactstrap'
import { Link } from 'gatsby'
// import PagerHeader from "../components/PagerHeader"

const PrivacyPage = () => (
    <Layout mainHeader={false}>
        <SEO title="Privacy Policy" description="Privacy Policy"/>
        {/*<PagerHeader/>*/}
        <Container fluid className="bg-black pt-5">
        <Container className="pt-5">
        <h4><strong>Introduction</strong></h4>
           <p>This website, controlled by Michael Rose Organisation Ltd. provides marketing information about Michael Rose Organisation Ltd. productions. Michael Rose Organisation Ltd. is the Data Controller, for the purposes of Data Protection Law, when your personal information is processed as a result of your visit to this website.</p>
            <p>Michael Rose Organisation Ltd. care about how your personal data is used. We respect and value the privacy of everyone who visits this website, MichaelRoseOrg.com and will only collect and use personal data in ways that are described here, and in a way that is consistent with our obligations and your rights under the law.</p>
            <p>Please read this Privacy Policy carefully and ensure that you understand it. Your acceptance of this Privacy Policy is deemed to occur upon your first use of this site.</p>
            <p>We are committed to providing you with relevant information about, and control over, how we process your personal information. </p>
            <p>This policy explains what personal information we may record, why we do so, how you may control the use of your information and how we protect your information. Details of your legal rights and how to exercise them are also set out below.</p>
            <p>This policy was last updated on 24.01.2019. Any future changes to our policy will be reflected here.</p>

            <h4><strong>Contacting us</strong></h4>
            <p>Questions, comments and requests regarding this privacy policy are welcomed and should be addressed by email to <a href="mailto:info@michaelroseorg.com">info@michaelroseorg.com</a></p>

            <h4><strong>What Does This Policy Cover?</strong></h4>
            <p>This Privacy Policy applies only to your use of this website. The website may contain links to other websites. Please note that we have no control over how your data is collected, stored, or used by other websites and we advise you to check the privacy policies of any such websites before providing any data to them.</p>

            <h4><strong>What Is Personal Data?</strong></h4>
            <p>Personal data is defined by the General Data Protection Regulation (EU Regulation 2016/679) (the “GDPR”) and the Data Protection Act 2018 (collectively, “the Data Protection Legislation”) as ‘any information relating to an identifiable person who can be directly or indirectly identified in particular by reference to an identifier’.</p>
            <p>Personal data is, in simpler terms, any information about you that enables you to be identified. Personal data covers obvious information such as your name and contact details, but it also covers less obvious information such as identification numbers, electronic location data, and other online identifiers.</p>

            <h4><strong>What personal information do we gather</strong></h4>
            <p>The only information we collect is anonymous technical information including IP address, browser type and version, operating system] and is used to understand how people interact with the website. </p>

            <h4><strong>Why we process your personal information</strong></h4>
            <p>We process your personal information gathered on this website in order to improve user experience and the website functionality. The specific purposes for which we may process information, and the lawful basis on which we do so in each case, are set out below.</p>

            <h4><strong>Tailoring this website to your needs</strong></h4>
            <p>We want to make our website easy to personalise.  We use preference cookies to store your preferences, such as website language selection, so that you do not need to make the same selections every time you visit our website. These cookies, stored by your browser on your device, will retain your preference choices until deleted. We record your consent to store cookies when you first visit the site and click on the cookie notice, and we provide details of how to change your settings [Digital Studio will embed <Link to="/cookie/">here</Link> – link to detailed cookie policy with details of site specific cookies].</p>

            <h4><strong>Improving the effectiveness of our website</strong></h4>
            <p>We want to make sure that our website enables people to learn about the show and to book tickets and that the site meets the needs of our customers and is easy to use. To do this, we need to understand how people use this site, where they spend time and which pages they find less useful.  We use Google Analytics to understand how you and other visitors use our website, for example recording in what order pages are visited and how long is spent on each, so that we may improve the website in future. [Google platform’s privacy policy may be found <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noopener noreferrer">here</a>].  We record your consent to store site analytics cookies when you first visit the site and click on the cookie notice. The lawful basis on which we process these analytics cookies is our legitimate interest in improving our website as we believe that the use of such cookies is not intrusive. Details of how to change your cookie settings so that you do not participate in our site analytics may be found [<Link to="/cookie/">here</Link> – link to detailed cookie information]</p>

            <h4><strong>Targeting and measuring the effectiveness of our digital advertising</strong></h4>
            <p>We know that many people visit a site several times to learn about the show and dates when tickets are available before deciding to book tickets.  We want to make sure that our digital advertisements on other websites and social media are seen by people likely to be interested in the show, including by visitors to this site who have not yet booked. We also want to understand the effectiveness of our advertising in encouraging visits to the website and ticket bookings so that we may improve it in future for example by reviewing the choice of advertising design and the websites and advertising networks used to reach possible customers. Achieving this may involve showing different versions of advertisements to different customers to measure the responses generated by each. </p>
            <p>We may use available browsing history, including visits to this website, to identify people to whom we wish to show advertisements on websites operated by third parties and to record how many visitors are referred to our site from other sites.  We use Sizmek advertising tracking cookies to achieve this and process these cookies on the basis of legitimate interest. We believe that showing relevant advertisements is in your interest as well as ours and we do not believe that such personalisation is generally considered intrusive.</p>
            <p>We respect any decision not to participate in personalised advertising, in which case third party sites may show you non-personalised advertising and you may still see advertisements for our show. </p>
            <p>We record your consent to store advertising tracking cookies when you first visit the site and click on the cookie notice. Details of how to change your settings to opt out of personalised advertising may be found [here – Digital Studio to embed link]. We use Sizmek to track our advertising. Details of how long your data is stored and how transfers outside the EEA are protected may be found in their <a href="https://www.sizmek.com/privacy-policy/">Privacy Policy.</a></p>
            <p>Our tracking cookie information is not shared with third parties for their own use, though we may use it to reconcile advertising costs with the publishers of third party sites. </p>

            <p><strong>Data Transfers</strong></p>
            <p>The personal information collected while you use this website may be transferred to countries outside the European Economic Area (EEA). This may happen, for example, when the computer servers used to host the website, or our advertising and marketing systems are located in a country outside the EEA. If we transfer your personal information outside of the EEA in this way, we will take steps to ensure that your privacy rights continue to be appropriately protected.</p>

            <p><strong>Do You Share My Personal Data?</strong></p>
            <p>We will not share any of your personal data with any third parties for their own use or for any purposes, subject to the following exception[s].</p>
            <p>We may engage with third-parties to assist in the provision of activities such as quizzes, newsletters and lotteries as described above.</p>
            <p>Anonymous analytics data for website usage and advertisement effectiveness is gathered as described above and is stored by a third-party</p>
            <p>In some limited circumstances, we may be legally required to share certain personal data, which might include yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the instructions of a government authority.</p>

            <h4><strong>Your rights</strong></h4>
            <p>We want to be transparent about how we process your data and to ensure that you can exercise your legal rights:</p>
            <ul>
                <li>To be informed – via this Privacy Policy which sets out how we collect and process data</li>
                <li>To access your data – please contact <a href="mailto:info@michaelroseorg.com">info@michaelroseorg.com</a> if you wish to confirm what data we hold about you</li>
                <li>To have your data corrected - please contact <a href="mailto:info@michaelroseorg.com">info@michaelroseorg.com</a> if you feel that data held is incorrect and want this rectified</li>
                <li>To have processing of your data ceased - please contact <a href="mailto:info@michaelroseorg.com">info@michaelroseorg.com</a> if you wish us to cease processing your data without erasing it</li>
                <li>To have your data erased – please contact <a href="mailto:info@michaelroseorg.com">info@michaelroseorg.com</a> to request deletion of data that we hold about you</li>
                <li>To data portability – we process data only to personalise our services to you so do not consider it appropriate to pass your data to other organisations. Some banks and utilities offer facilities to switch accounts, but this is not applicable to our business.</li>
                <li>To object to processing of your data on the basis of legitimate interest or for statistical purposes – please contact <a href="mailto:info@michaelroseorg.com">info@michaelroseorg.com</a>, or amend your cookie settings.</li>
            </ul>

            <h4><strong>Complaints</strong></h4>
            <p>To exercise all relevant rights, queries of complaints please in the first instance contact  <a href="mailto:info@michaelroseorg.com">info@michaelroseorg.com</a></p>
             <p>You can contact the Information Commissioners Office on 0303 123 1113 or via email <a href="https://ico.org.uk/global/contact-us/email/">https://ico.org.uk/global/contact-us/email/</a> or at the Information Commissioner’s Office, Wycliffe House, Water Lane, Wilmslow, Cheshire. SK9 5AF.</p>
            <p>Back to <a href="/" >Home</a></p>
             </Container>
            </Container>
    </Layout>
)

export default PrivacyPage